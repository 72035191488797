<template>
  <v-container class="black">
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <v-card
          class="mb-3"
          rounded
          dark
        >
          <v-card-text>
            <iframe
              src="https://www.youtube.com/embed/LXpaauHFEbE?si=N5Mmh5VxMTo1nFCg"
              title="YouTube video player"
              frameborder="0"
              width="100%"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
            <div style="text-align:center">
              See more videos on our <v-icon>mdi-youtube</v-icon> channel
              <a
                href="https://www.youtube.com/user/GOryanGOvideos/videos"
                style="text-decoration:none;color:#E1AE05"
              ><b>here</b>
              </a>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          class="pa-3 mb-3"
          rounded
          dark
        >
          <v-card-title>
            <span text-align="center">Latest Release</span>
          </v-card-title>
          <v-card-text align="center">
            <a
              href="https://goryangomusic.myshopify.com/products/one-step-closer-cd"
              style="text-decoration: none;"
            >
              <v-img
                src="/static/one-step-closer.png"
              />
            </a>&nbsp;
            <v-spacer />
            &nbsp;Buy your copy here:&nbsp;
            <v-spacer />
            <a
              href="https://goryangomusic.myshopify.com/products/one-step-closer-cd"
              style="text-decoration: none;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4em"
                height="4em"
                viewBox="0 0 24 24"
              >
                <path
                  d="M18.61 5.89c-.01-.1-.11-.16-.17-.16c-.07-.01-1.61-.12-1.61-.12s-1.07-1.06-1.18-1.18c-.12-.12-.35-.08-.44-.06c-.01 0-.21.07-.6.18c-.36-1.05-.99-1.97-2.18-1.97c-.32-.4-.71-.58-1.05-.58c-2.58 0-3.81 3.22-4.2 4.86l-1.81.56c-.55.18-.57.2-.64.72c-.06.4-1.52 11.72-1.52 11.72L14.61 22l6.18-1.34S18.62 6 18.61 5.89M14 4.76c-.31.09-.63.19-1 .3v-.21c0-.64-.07-1.15-.21-1.56c.56.07.94.71 1.21 1.47m-1.92-1.34c.16.4.26.97.26 1.74v.11c-.63.19-1.31.41-1.99.62c.38-1.49 1.1-2.2 1.73-2.47m-.77-.73c.11 0 .22.04.33.11c-.83.39-1.71 1.37-2.09 3.32c-.55.18-1.08.34-1.55.5c.42-1.5 1.46-3.93 3.31-3.93m1.19 6.46l-.74 2.27s-.67-.36-1.49-.36c-1.2 0-1.27.75-1.27.94c0 1.04 2.71 1.43 2.71 3.86c0 1.91-1.21 3.14-2.84 3.14c-1.96 0-2.96-1.22-2.96-1.22l.52-1.73s1.03.88 1.9.88c.57 0 .8-.43.8-.77c0-1.35-2.21-1.41-2.21-3.63c0-1.87 1.34-3.68 4.05-3.68c1.03 0 1.53.3 1.53.3m2.93-3.86l1.32 1.31l.96.08c.34 2.32 1.48 10.05 1.95 13.2l-5 1.09l.77-15.68z"
                  fill="#fff"
                />
              </svg>
            </a>
            <v-spacer />
            <span style="text-wrap: nowrap;">or stream it on all streaming platforms</span>
          </v-card-text>
        </v-card>
        <v-container
          class="black"
        >
          <v-row
            dense
          >
            <v-col />
            <v-col
              justify-center
            >
              <v-card
                rounded
                dark
              >
                <v-card-title>Also available</v-card-title>
                <v-card-text>
                  <span style="text-wrap: nowrap;">Debut EP <strong>"Driving out the Silence"</strong></span>
                  &nbsp;
                  <a
                    href="https://goryangomusic.myshopify.com/products/driving-out-the-silence-ep-cd"
                    style="text-decoration: none;"
                  >
                    <v-img
                      src="/static/driving-out-the-silence.jpg"
                      :width="200"
                    />
                  </a>
                  &nbsp;
                  <v-spacer />
                  <a
                    href="https://music.apple.com/us/album/driving-out-the-silence-ep/1706756464"
                    style="text-decoration:none"
                  ><v-icon>mdi-apple</v-icon></a>&nbsp;
                  <a
                    href="https://open.spotify.com/album/6LuOSZv8kZdYjnUz5QGgHT?si=AgYGKKLpQ0-35uFMiUr7qA"
                    style="text-decoration:none"
                  ><v-icon>mdi-spotify</v-icon></a>&nbsp;
                  <a
                    href="https://goryango1.bandcamp.com/"
                    style="text-decoration:none"
                    class="white--text"
                  >Bandcamp</a>
                  <a
                    href="https://music.apple.com/us/album/driving-out-the-silence-ep/1706756464"
                    style="text-decoration:none"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col />
          </v-row>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <shows />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-container class="black">
          <v-row
            dense
          >
            <v-col />
            <v-col
              justify-center
            >
              <v-card
                class="mx-auto"
                rounded
                dark
              >
                <v-card-title>
                  Buy some merch:&nbsp;
                </v-card-title>
                <v-card-text>
                  <a href="https://goryangomusic.myshopify.com">
                    <v-img
                      src="/static/grey-shirt-transparent.png"
                      title="black-shirts"
                      max-width="200px"
                    />
                  </a>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col />
          </v-row>
        </v-container>
        <v-container
          class="black"
        >
          <v-row
            dense
          >
            <v-col />
            <v-col
              justify-center
            >
              <v-card
                rounded
                dark
              >
                <v-card-text>
                  <div>
                    <a
                      href="https://www.wxii12.com/article/north-carolina-local-bands-to-watch-goryango/61826591"
                      style="text-decoration:none;color:#E1AE05"
                      max-width="50%"
                    ><v-img
                      src="/static/wxii.png"
                      title="wxii"
                    />
                    </a>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col />
          </v-row>
        </v-container>
        <v-container
          class="black"
        >
          <v-row
            dense
          >
            <v-col />
            <v-col
              justify-center
            >
              <v-card
                class="pa-3 mx-auto"
                rounded
                dark
              >
                <v-card-title>
                  Booking:
                </v-card-title>
                <v-card-text>
                  <div style="text-wrap: nowrap;">
                    <a
                      href="mailto:booking@goryangomusic.com"
                      style="text-decoration:none"
                    >
                      <v-icon>mdi-email</v-icon>
                    </a>&nbsp;
                    <a href="mailto:booking@goryangomusic.com">
                      <span class="white--text">booking@goryangomusic.com</span>
                    </a>
                  </div>
                </v-card-text>
                <v-card-title>
                  Follow us:
                </v-card-title>
                <v-card-text>
                  <a
                    href="https://www.facebook.com/GOryanGOband"
                    style="text-decoration:none"
                  >
                    <v-icon large>mdi-facebook</v-icon>
                  </a>
                  &nbsp;
                  <a
                    href="https://www.instagram.com/goryangomusic/"
                    style="text-decoration:none"
                  >
                    <v-icon large>mdi-instagram</v-icon>
                  </a>
                  &nbsp;
                  <a
                    href="https://www.youtube.com/user/GOryanGOvideos/videos"
                    style="text-decoration:none"
                  >
                    <v-icon large>mdi-youtube</v-icon>
                  </a>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col />
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {
      Shows: () => import('@/components/Shows.vue')
    },
    data: () => ({
      overlay: false,
      videos: [
        {
          'title': 'Sorry',
          'link': 'https://www.youtube.com/embed/NtQj45AsN_Q?start=539'
        },
        {
          'title': 'Discompose',
          'link': 'https://www.youtube.com/watch?v=Rd_uo_Ryt6M'
        }
      ],
      dialog: true,
      goVote: function () {
        window.open('https://tophitmaker.org/2024/goryango', '_blank')
      }
    })
  }
</script>

<style scoped>
  .gallery-card {
    transform-origin: center center 0;
    transition: .3s cubic-bezier(.25,.8,.50,1);
  }

  .fade-transition-leave,
  .fade-transition-leave-active,
  .fade-transition-leave-to {
    display: none;
  }
</style>
